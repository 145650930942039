import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Catering from "./clientappCatering/Catering";
import HairAndBeauty from "./clientappHairtechnicians/HairAndBeauty";
import Merchandise from "./clientappMerchandise/Merchandise";
import DIYTechniciansTechnician from "./clientappDIYTechnicians/DIYTechnicians";
import HomeDashboard from"./HomeDashboard";
import MainBodyHairTechnician from "./clientappHairtechnicians/MainBodyHairTechnician";
import MainBodyDIY from "./clientappDIYTechnicians/MainBodyDIY";
import MainBodyMerchandise from "./clientappMerchandise/MainBodyMerchandise";
import MainBodyCatering from "./clientappCatering/MainBodyCatering";
import HomeConnections from "./clientappConnections/HomeConnections";
import Disclaimer from "./footer/disclaimer";
import Help from "./footer/help";
import Follow from "./footer/followus";
import Join from "./footer/joinus";
import Ask from "./footer/askus";
import Contact from  "./footer/askus";
// import DetailsConnections from "./clientappConnections/loadPages/AssetDetailPageDev";
// import DetailsConnectionsLive from "./clientappConnections/loadPages/AssetDetailPageLive";
import MainBodyConnections from "./clientappConnections/MainBodyConnections";
import AdminBody from "./admin/AdminDashBoardBody";

class Main extends Component {
  render() {
    return (
      <div>  
        <AdminBody/>    
        <MainBodyHairTechnician/>
        <MainBodyDIY/>
        <MainBodyMerchandise/>
        <MainBodyCatering/>
        <MainBodyConnections/>
        <HashRouter>
          <Route exact path="/" component={HomeDashboard} />
          <Route exact path="/home" component={HomeDashboard} />
          <Route path="/homedashboard" component={HomeDashboard} />  
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/help" component={Help} />          
          <Route path="/followus" component={Follow} />
          <Route path="/contact" component={Ask} />
          <Route path="/joinus" component={Join} />
          <Route path="/askus" component={Ask} />
          <Route path="/hairandbeauty" component={HairAndBeauty} />
          <Route path="/catering" component={Catering} /> 
          <Route path="/merchandise" component={Merchandise} />
          <Route path="/DIYTechnicians" component={DIYTechniciansTechnician} /> 
          <Route path="/connections" component={HomeConnections} /> 
          {/* <Route path="/productdetailsdev" component={DetailsConnections} />
          <Route path="/productdetails" component={DetailsConnectionsLive} /> */}
              
         <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
