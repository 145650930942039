import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LocalStorageService from '../services/localStorageService';
import LoadMoreAssets from './loadPages/LoadMoreAssetsRoot';
import NavBarAllcategory from "./responsive/NavbarDIYTechnicians";

class DIYTechnicians extends Component {
    render() {
    const { t } = this.props;
    LocalStorageService("remove","servicecategory");
    LocalStorageService("set","servicecategory","assembling");
    return (
    <div className="content-akwaba" > 
    <p>General services, such as assembling, fixing, disassabling, engineering</p>
      <span><NavBarAllcategory/> </span>
        <LoadMoreAssets/> 
        <p></p>
      </div>
    );
  }
}


export default withTranslation() (DIYTechnicians);
