import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Services from "./pages/Services";
import Contact from "./pages/messages/SendContactMessage";
import Menue from "./pages/Menue";
import References from "./pages/References";
import Booking from "./pages/Booking";
import Appointments from "./pages/Appointments";
import Interets from "./pages/Interets";
import RegisterSimple from "./registerlogin/RegisterSimple";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import MessageMyAdmin from "./admin/myAdmin/MessageAdminPage";
import MyAdmin from "./admin/myAdmin/MyDashBoard";
import ServiceSubscription from "./admin/myAdmin/ServiceSubscription";
import Logout from "./registerlogin/Logout";

import UsefullLinks from "./admin/myAdmin/UsefullLinks";
import ThankYouUploaded from "./uploadMedia/ThankyouAfterUploaded";
import UploadAssets from "./uploadMedia/UploadTextInputs";
import AssetDetailsDev from "./loadPages/AssetDetailPageDev";
import AssetDetailsLive from "./loadPages/AssetDetailPageLive";
import UploadVideo from "./uploadMedia/UploadVideo";
import UploadPictures from "./uploadMedia/UploadPictures";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";

import AppointmentMessages from "./admin/appointments/AppointmentMessagePage";
import BookingMessages from "./admin/bookings/BookingMessagesPage";
import ExpressedInterestMessages from "./admin/interestexpressed/ExpressedInterestMessagesPage";
import GenerateInvoice from "./admin/invoice/GenerateInvoice";
import InvoicePage from "./admin/invoice/InvoicePage";
import GenerateProductMenue from "./admin/ProductMenue/GenerateProductMenue";
import ProductMenueASAChefPage from "./admin/ProductMenue/ProductMenuePageAsProductOwner";
import ProductMenuePage from "./admin/ProductMenue/ProductMenuePage";
import GenerateOrder from "./admin/orders/GenerateOrder";
import OrdersPage from "./admin/orders/OrdersPage";
import MakePayment from "./payments/MakePayment";
import SendPaymentConfirmation from "./payments/SendPaymentConfirmation";
import InvoiceByNumberPage from "./admin/invoice/InvoiceByNumberPage";
// import Help from "./help_merchandise"
import CollectPaymentNumber from "./payments/CollectPaymentNumber";
import MakePaymentRoot from "./payments/MakePaymentRoot";

class MainBodyConnections extends Component {
  render() {
    return (
      <div>
        
        <HashRouter> 
          {/* <Route path="/helpconnections" component={Help} />        */}
          <Route path="/appointmentmessagesconnections" component={AppointmentMessages} /> 
          <Route path="/bookingmessagesconnections" component={BookingMessages} /> 
          <Route path="/interestexpressedconnections" component={ExpressedInterestMessages} />
        
          <Route path="/uploadedthanksconnections" component={ThankYouUploaded} />
          <Route path="/productdetailsdev" component={AssetDetailsDev} />
          <Route path="/productdetails" component={AssetDetailsLive} />          
          <Route path="/servicesconnections" component={Services} />
          <Route path="/menueconnections" component={Menue} />
          <Route path="/referenceconnections" component={References} />
          <Route path="/bookingconnections" component={Booking} />
          <Route path="/appointmentsconnections" component={Appointments} />
          <Route path="/interetsconnections" component={Interets} /> 
          <Route path="/registersimpleconnections" component={RegisterSimple} />
          <Route path="/changepasswordconnections" component={ChangePassword} />
          <Route path="/thankyouregisteredconnections" component={ThankyouAfterRegistration} /> 
          <Route path="/thankyoupasswordchangedconnections" component={ThankyouPasswordChanged} />           
          <Route path="/adminpageconnections" component={MyAdmin} />
          <Route path="/servicesubscriptionconnections" component={ServiceSubscription} />
          <Route path="/loginscreenconnections" component={Loginscreen} />
          <Route path="/loginconnections" component={Login} />
          <Route path="/contactconnections" component={Contact} />
          <Route path="/messageadminconnections" component={MessageMyAdmin} />
          <Route path="/usefulllinksconnections" component={UsefullLinks} />
         
          <Route path="/logoutconnections" component={Logout} /> 
          <Route path="/uploadmerchandisedetails" component={UploadAssets} />
          <Route path="/uploadvideosconnections" component={UploadVideo} />
          <Route path="/uploadpicturesconnections" component={UploadPictures} /> 
          <Route path="/generateinvoiceconnections" component={GenerateInvoice} /> 
          <Route path="/invoicepageconnections" component={InvoicePage} />
          <Route path="/invoicebynumberpageconnections" component={InvoiceByNumberPage} />          
          <Route path="/generateproductmenueconnections" component={GenerateProductMenue} /> 
          <Route path="/productmenuepageconnections" component={ ProductMenuePage} />
          <Route path="/productmenueasachefpageconnections" component={ProductMenueASAChefPage} />
          <Route path="/generateorderconnections" component={GenerateOrder} /> 
          <Route path="/orderspageconnections" component={OrdersPage} /> 
          <Route path="/makepaymentconnections" component={MakePayment} />
          <Route path="/sendpaymentconfirmationconnections" component={SendPaymentConfirmation} />          
          <Route path="/commoditypaymentnumber" component={CollectPaymentNumber} /> 
          <Route path="/makepaymentcom" component={MakePaymentRoot} />  
          <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(MainBodyConnections);
