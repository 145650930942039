import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import DataTable from './InvoiceDataTable';
import LocalStorageService from '../../../services/localStorageService';
import GetUrl from "../../services/urlServiceMerchandise";
import CurrencyFormat from 'react-currency-format';
//class InvoicePage extends Component {
  const InvoiceByNumberPage = () => {
    const [invoiceNumberOrId, setInvoiceNumberOrId] = useState('');
  const [invoices, setInvoices] = useState('');
  const [invoices2, setInvoices2] = useState('');
  const [results, setResult] = useState([]);
  const [item1Name, setItem1Name] = useState([]);
  const [item1Price, setItem1Price] = useState([]);
  const [item1Qty, setItem1Qty] = useState([]);
  const [item2Name, setItem2Name] = useState([]);
  const [item2Price, setItem2Price] = useState([]);
  const [item2Qty, setItem2Qty] = useState([]);
  const [item3Name, setItem3Name] = useState([]);
  const [item3Price, setItem3Price] = useState([]);
  const [item3Qty, setItem3Qty] = useState([]);
  const [item4Name, setItem4Name] = useState([]);
  const [item4Price, setItem4Price] = useState([]);
  const [item4Qty, setItem4Qty] = useState([]);
  const [item5Name, setItem5Name] = useState([]);
  const [item5Price, setItem5Price] = useState([]);
  const [item5Qty, setItem5Qty] = useState([]);
  const [item6Name, setItem6Name] = useState([]);
  const [item6Price, setItem6Price] = useState([]);
  const [item6Qty, setItem6Qty] = useState([]);

  
  const getData = async () => {
    const API_PATH = getApiPath(); 
    
    const body = {
      invoiceNumber: invoiceNumberOrId
    };
    const result = await 
    axios({
      method: "post",     
      url: `${API_PATH}`,
      data: body,
    })
    setResult(result.data);

    console.log('result:', results);
  };

  useEffect(() => {
    getData();
    }, [invoices])

// useEffect (() =>{
//   retrieveInvoiceByNumber(); 
// }, []);

useEffect (() =>{
  setInvoiceNumberOrId(LocalStorageService("get", "invoiceNumber"))
}, );


 function getToken (){
  return LocalStorageService("get", "token"); 
}; 



// function  getInvoiceNumber (){
//   return LocalStorageService("get", "invoiceNumber"); 
// }; 

const  getApiPath = () =>  {   
  return GetUrl("retrieveInvoiceByNumberhairstyle");
};

const retrieveInvoiceByNumber = () =>{
const API_PATH = getApiPath(); 
const body = {
  invoiceNumber: invoiceNumberOrId
};
if( getToken()) {
axios({
  method: "post",     
  url: `${API_PATH}`,
  data: body,
})
.then(result => {
    if (result.status === 200) {  
      const inv = result?.data;
      setInvoices(inv);    
        console.log("invoices in dom: ", invoices);
      }
    })
    .catch(function (error) {    
      console.log(error);
    });  
       
    }
  };
 
  

return (
  <div  className="content-akwaba">
        {results.map((item, i) => {
          return (
            <div>
            <Row>           
             <Col md={{ span: 6, offset: 4 }}>  
            <div className ="invoices-container">
          <ul Style="list-style-type: none">         
            <li Style="color:Black"><h3><b>*****  Invoice Id: {item.Id}    *********</b></h3></li>
            <li key={i}>Invoice Number: {item.InvoiceNumber}</li>
            <li key={i}>created_at: {item.created_at}</li> 
            <p></p>
            <li Style="color:purple"><h4><b>Caterer info</b></h4></li>
            <li key={i}>Your service name: {item.CatererCompanyName}</li>         
            <li key={i}>CatererCompanyNumber: {item.CatererCompanyNumber}</li>
            <li key={i}>email: {item.email}</li>
             <p></p>
            <li Style="color:blue"><h4><b>Customer info</b></h4></li>
            <li key={i}>title: {item.title}</li>
            <li key={i}>firstName: {item.firstName}</li>
             <li key={i}>lastName: {item.lastName}</li>
             <li key={i}>phoneNumber: {item.phoneNumber}</li>
            
          <p></p>
          <li Style="color:magenta"><h4><b>Purchase info</b></h4></li>
          <li key={i}>Dish name: {item.DishName}</li>                
          <li key={i}>Customer Phone Number: {item.CustomerPhoneNumber}</li>        
          <li key={i}>{item.Item1Name? "Item1 Name: "+item.Item1Name: ""}</li>
          <li key={i}>{item.Item1Name? "Item1 Price: "+"£"+item.Item1Price: ""}</li>
          <li key={i}>{item.Item1Name?"Item1 Qty: "+item.Item1Qty: ""}</li>
          <li key={i}>{item.Item2Name? "Item2 Name: "+item.Item2Name: ""}</li>
          <li key={i}>{item.Item2Name? "Item2 Price: "+"£"+item.Item2Price: ""}</li>
          <li key={i}>{item.Item2Name? "Item2 Qty: "+item.Item2Qty: ""}</li>
          <li key={i}>{item.Item3Name? "Item3 Name: "+item.Item3Name: ""}</li>
          <li key={i}>{item.Item3Name? "Item3 Price: "+"£"+item.Item3Price: ""}</li>
          <li key={i}>{item.Item3Name? "Item3 Qty: "+item.Item3Qty: ""}</li>
          <li key={i}>{item.Item4Name? "Item4 Name: "+item.Item4Name: ""}</li>
          <li key={i}>{item.Item4Name? "Item4 Price: "+"£"+item.Item4Price: ""}</li>
          <li key={i}>{item.Item4Name? "Item4 Qty: "+item.Item4Qty: ""}</li>
          <li key={i}>{item.Item5Name? "Item5 Name: "+item.Item5Name: ""}</li>
          <li key={i}>{item.Item5Name? "Item5 Price: "+"£"+item.Item5Price: ""}</li>
          <li key={i}>{item.Item5Name? "Item5 Qty: "+item.Item5Qty: ""}</li>
          <li key={i}>{item.Item6Name? "Item6 Name: "+item.Item6Name: ""}</li>
          <li key={i}>{item.Item6Name?  "Item6 Price: "+"£"+item.Item6Price : ""} </li>
          <li key={i}>{item.Item6Name? "Item6 Qty: "+item.Item6Qty: ""}</li>
          <li key={i}>Total: £{item.total}</li>
          <li key={i}>VAT: £{item.VAT}</li>
          <li key={i}>Grand_total: £{item.Grand_total}</li>
           <p></p>
           <p></p>
         
          <li Style="color:green"><h4><b>Invoice info</b></h4></li>
          <li key={i}>Paid Status: {item.PaidStatus==1?'Paid':'Unpaid'}</li>
          <li key={i}>Date Paid: {item.DatePaid?item.DatePaid:'N/A'}</li>         
           <li key={i}>Refund Status: {item.RefundStatus==1?'Refunded':'N/A'}</li>
           <li key={i}>Date Refund: {item.DateRefund?item.DateRefund:'N/A'}</li>
          <p></p>         
          <li>*********************************************************</li>
      </ul>
      </div> 
             </Col>
           </Row>
      </div>
          )
        })}   
    </div>
   
)
  }

 
export default InvoiceByNumberPage;