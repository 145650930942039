import React, { Component } from "react";
import {NavLink, HashRouter } from "react-router-dom";
import { useTranslation  } from "react-i18next";
import { Redirect } from "react-router";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from '../../../services/localStorageService';


function ServiceSubscription(){

  LocalStorageService("remove","servicecategory");
  LocalStorageService("set","servicecategory","hairstyle");

  function loginEmail (){
    return LocalStorageService("get", "userEmail"); 
  }; 
  
  function hasAMenue (){
    return LocalStorageService("get", "hasMenue")==1; 
  }; 
  function getToken (){
    return LocalStorageService("get", "token"); 
  }; 

  const hasMenue =  hasAMenue();

   const email = loginEmail();

  const token = getToken();

  const isLoggedin = token!=null;

  const { t } = useTranslation(); 
      return (       
        <div className="container">    
     
     <div className="akwaba-content-admin"> 
           <Row>
            <Col md={{ span: 2, offset: 4 }}>
              {" "}             
              <p></p>
            </Col>
          </Row>
          <Row>
            <HashRouter>
              <Col md={{ span: 4 , offset: 3}}>
                {" "}
               {isLoggedin && (<div>
                <span>Hello {LocalStorageService("get", "userEmail")}<br/></span>
                <div></div>
                <span><b>Follow these steps to upload your hair style details</b></span> 
                <div className="admin-home">                                
                    <ol>
                    <li>Fill your personal info<br/></li>
                    <li>Fill the info about the hair style<br/></li>
                    <li>Fill info about the Place where the hair style is<br/></li>
                    <li>Upload 1 mandatory picture and 3 optional picture(4 pictures in total)<br/></li>
                    <li>Upload a 1 to 2mn  video of the hair style <br/> </li>
                    </ol>
                    </div>  
                    <div></div> 
                  <NavLink to="/uploadhairstyledetails">
                    <div className="admin-link-to-post">
                    <b>Click here to create hair style page</b> <br/>                            
                    </div>
                  </NavLink> 
                              
                    
                </div>)}
                {!isLoggedin && (<div>
                  <span>Please Login. If not registered yet please register and login</span>
                  </div>)}
                
              </Col>
             
            </HashRouter>
          </Row> 

          <Row>
            <Col md={{ span: 2, offset: 4 }}>
          <div></div> 
                  <NavLink to="/messageadminhairstyle">
                    <div className="admin-link-to-post">
                    <b>Check my messages</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
          </Row>

          <Row>
            <Col md={{ span: 2, offset: 4 }}>
          <div></div> 
                  <NavLink to="/bookingmessageshairstyle">
                    <div className="admin-link-to-post">
                    <b>Check my bookings</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
          </Row>     


          <Row>
            <Col md={{ span: 2, offset: 4 }}>
          <div></div> 
                  <NavLink to="/interestexpressedhairstyle">
                    <div className="admin-link-to-post">
                    <b>Check my expressed interests</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
            </Row> 

          <Row>
            <Col md={{ span: 2, offset: 4 }}>
             <div></div> 
                  <NavLink to="/appointmentmessageshairstyle">
                    <div className="admin-link-to-post">
                    <b>Check my appointments</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
          </Row>

           <Row>
            <Col md={{ span: 2, offset: 4 }}>
             <div></div> 
                  <NavLink to="/changepasswordhairstyle">
                    <div className="admin-link-to-post">
                    <b>Reset Password</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
             </Row>  

               <Row>
            <Col md={{ span: 2, offset: 4 }}>
             <div></div> 
                  <NavLink to="/generateinvoicehairstyle">
                    <div className="admin-link-to-post">
                    <b>Generate Invoice</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
             </Row> 

          <Row>
            <Col md={{ span: 2, offset: 4 }}>
             <div></div> 
                  <NavLink to="/invoicepagehairstyle">
                    <div className="admin-link-to-post">
                    <b>See invoice I sent</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
          </Row>

          <Row>
            <Col md={{ span: 2, offset: 4 }}>
             <div></div> 
                  <NavLink to="/generatemenuehairstyle">
                    <div className="admin-link-to-post">
                    <b>Generate hairstyle menue</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
           </Row>


              {hasMenue && (
            <Row>
            <Col md={{ span: 2, offset: 4 }}>
             <div></div> 
                  <NavLink to="/orderspagehairstyle">
                    <div className="admin-link-to-post">
                    <b>Show orders I received</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
           </Row>
             )}        
          

          <Row>
            <Col md={{ span: 2, offset: 4 }}>
              {" "}             
              <p></p>
            </Col>
          </Row> 

          <Row>
            <Col md={{ span: 2, offset: 3 }}></Col>
          <div className="admin-home">
         <h4>Terms and contions:</h4> 
         <span>The subscription and use of this platform(manblaysaviours.com) is free.
          However admin fees for generating an invoice or taking a payment might apply.   
           </span>
        </div>
       </Row> 

       <Row>
            <Col md={{ span: 2, offset: 4 }}>
              {" "}             
              <p></p>
            </Col>
      </Row>    
        </div>
    </div>
    )
  }
 
export default (ServiceSubscription);