import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LocalStorageService from '../services/localStorageService';
import LoadMoreAssets from './loadPages/LoadMoreAssetsRoot';
 import NavBarCatering from "./responsive/catering/NavBarCatering";

class Catering extends Component { 
  render() {
    const { t } = this.props; 
    return (
      <div className="content-akwaba" > 
    <p>Best chefs with best dishes in the world.</p>
      <span><NavBarCatering/> </span>        
         <LoadMoreAssets/> 
        <p></p>
      </div>
    );
  }
}



export default withTranslation() (Catering);
