import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Services from "../clientappDIYTechnicians/pages/Services";
import Contact from "../clientappDIYTechnicians/pages/messages/SendContactMessage";
import Menue from "../clientappDIYTechnicians/pages/Menue";
import References from "../clientappDIYTechnicians/pages/References";
import Booking from "../clientappDIYTechnicians/pages/Booking";
import Appointments from "../clientappDIYTechnicians/pages/Appointments";
import Interets from "../clientappDIYTechnicians/pages/Interets";
import RegisterSimple from "../clientappDIYTechnicians/registerlogin/RegisterSimple";
import ChangePassword from "../clientappDIYTechnicians/registerlogin/ChangePassword";
import Loginscreen from "../clientappDIYTechnicians/registerlogin/LoginScreen";
import Login from "../clientappDIYTechnicians/registerlogin/Login";
import MessageMyAdmin from "../clientappDIYTechnicians/admin/myAdmin/MessageAdminPage";
import MyAdmin from "../clientappDIYTechnicians/admin/myAdmin/MyDashBoard";
import ServiceSubscription from "../clientappDIYTechnicians/admin/myAdmin/ServiceSubscription";
import Logout from "../clientappDIYTechnicians/registerlogin/Logout";
import Partnerservice from "../clientappDIYTechnicians/partners/Partnerservice";
import UsefullLinks from "../clientappDIYTechnicians/admin/myAdmin/UsefullLinks";
import ThankYouUploaded from "../clientappDIYTechnicians/uploadMedia/ThankyouAfterUploaded";
import UploadAssets from "../clientappDIYTechnicians/uploadMedia/UploadTextInputs";
import AssetDetailsDev from "../clientappDIYTechnicians/loadPages/AssetDetailPageDev";
import AssetDetailsLive from "../clientappDIYTechnicians/loadPages/AssetDetailPageLive";
import UploadVideo from "../clientappDIYTechnicians/uploadMedia/UploadVideo";
import UploadPictures from "../clientappDIYTechnicians/uploadMedia/UploadPictures";
import ThankyouAfterRegistration from "../clientappDIYTechnicians/registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "../clientappDIYTechnicians/registerlogin/ThankyouPasswordChanged";
import BuyerService from "../clientappDIYTechnicians/buyers/BuyerServices";
import BuyerMessages from "../clientappDIYTechnicians/buyers/MyMessagePage";
import BuyerAppointments from "../clientappDIYTechnicians/buyers/MyAppointments";
import BuyerProperties from "../clientappDIYTechnicians/buyers/MyProperty";
import AppointmentMessages from "../clientappDIYTechnicians/admin/appointments/AppointmentMessagePage";
import BookingMessages from "../clientappDIYTechnicians/admin/bookings/BookingMessagesPage";
import ExpressedInterestMessages from "../clientappDIYTechnicians/admin/interestexpressed/ExpressedInterestMessagesPage";
import GenerateInvoice from "../clientappDIYTechnicians/admin/invoice/GenerateInvoice";
import InvoicePage from "../clientappDIYTechnicians/admin/invoice/InvoicePage";
import GenerateProductMenue from "./admin/ProductMenue/GenerateProductMenue";
import ProductMenuePageAsProductOwner from "./admin/ProductMenue/ProductMenuePageAsProductOwner";
import ProductMenuePage from "./admin/ProductMenue/ProductMenuePage";
import GenerateOrder from "../clientappDIYTechnicians/admin/orders/GenerateOrder";
import OrdersPage from "../clientappDIYTechnicians/admin/orders/OrdersPage";
import MakePayment from "../clientappDIYTechnicians/payments/MakePayment";
import SendPaymentConfirmation from "../clientappDIYTechnicians/payments/SendPaymentConfirmation";
import InvoiceByNumberPage from "../clientappDIYTechnicians/admin/invoice/InvoiceByNumberPage";
import Help from "./help_astec";
import MakePaymentRoot from "./payments/MakePaymentRoot";
import CollectPaymentNumber from "./payments/CollectPaymentNumber";

class MainBodyDIY extends Component {
  render() {
    return (
      <div>        
        <HashRouter>
          <Route path="/helpassembling" component={Help} />        
          <Route path="/appointmentmessagesassembling" component={AppointmentMessages} /> 
          <Route path="/bookingmessagesassembling" component={BookingMessages} /> 
          <Route path="/interestexpressedassembling" component={ExpressedInterestMessages} />
          <Route path="/buyerservicesassembling" component={BuyerService} /> 
          <Route path="/buyerappointmentsassembling" component={BuyerAppointments} /> 
          <Route path="/BuyerPropertiesassembling" component={BuyerProperties} /> 
          <Route path="/buyermessagesassembling" component={BuyerMessages} /> 
          <Route path="/uploadedthanksassembling" component={ThankYouUploaded} />
          <Route path="/assetdetailsdevassembling" component={AssetDetailsDev} />
          <Route path="/assetdetailsliveassembling" component={AssetDetailsLive} />          
          <Route path="/servicesassembling" component={Services} />
          <Route path="/menueassembling" component={Menue} />
          <Route path="/referenceassembling" component={References} />
          <Route path="/bookingassemblinge" component={Booking} />
          <Route path="/appointmentsassembling" component={Appointments} />
          <Route path="/interetsassembling" component={Interets} /> 
          <Route path="/registerassembling" component={RegisterSimple} />
          <Route path="/changepasswordassembling" component={ChangePassword} />
          <Route path="/thankyouregisteredassembling" component={ThankyouAfterRegistration} /> 
          <Route path="/thankyoupasswordchangedassembling" component={ThankyouPasswordChanged} />           
          <Route path="/adminpageassembling" component={MyAdmin} />
          <Route path="/servicesubscriptionassembling" component={ServiceSubscription} />
          <Route path="/loginscreenassembling" component={Loginscreen} />
          <Route path="/loginassembling" component={Login} />
          <Route path="/contactassembling" component={Contact} />
          <Route path="/messageadminassembling" component={MessageMyAdmin} />
          <Route path="/usefulllinksassembling" component={UsefullLinks} />
          <Route path="/partnerserviceassembling" component={Partnerservice} />
          <Route path="/logoutassembling" component={Logout} /> 
          <Route path="/uploadDIYServiceDetails" component={UploadAssets} />
          <Route path="/uploadvideosassembling" component={UploadVideo} />
          <Route path="/uploadpicturesassembling" component={UploadPictures} /> 
          <Route path="/generateinvoiceassembling" component={GenerateInvoice} /> 
          <Route path="/invoicepageassembling" component={InvoicePage} />
          <Route path="/invoicebynumberpageassembling" component={InvoiceByNumberPage} />          
          <Route path="/generateproductmenueassembling" component={GenerateProductMenue} /> 
          <Route path="/productmenuepageassembling" component={ ProductMenuePage} />
          <Route path="/productmenuepageasproductowner" component={ProductMenuePageAsProductOwner} />
          <Route path="/generateorderassembling" component={GenerateOrder} /> 
          <Route path="/orderspageassembling" component={OrdersPage} /> 
          <Route path="/makepaymentassembling" component={MakePayment} />
          <Route path="/sendpaymentconfirmationassembling" component={SendPaymentConfirmation} />          
          <Route path="/geneservpaymentnumber" component={CollectPaymentNumber} /> 
          <Route path="/makepaymentgens" component={MakePaymentRoot} />   
          
          <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(MainBodyDIY);
