import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

import LocalStorageService from '../../../services/localStorageService';
import GetUrl from "../../services/urlServiceConnections";
import CurrencyFormat from 'react-currency-format';

  const ProductMenuePageAsProductOwner = () => {

    LocalStorageService("remove","servicecategory");
    LocalStorageService("set","servicecategory","assembling");
    
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhoneNumber, setContactPhoneNumber] = useState('');    
  const [foodMenue, setHairStyleMenue] = useState('');
  const [invoices2, setInvoices2] = useState('');
  const [results, setResult] = useState([]);
  const [item1Name, setItem1Name] = useState([]);
  const [item1Price, setItem1Price] = useState([]);
  const [item1Qty, setItem1Qty] = useState([]);
  const [item2Name, setItem2Name] = useState([]);
  const [item2Price, setItem2Price] = useState([]);
  const [item2Qty, setItem2Qty] = useState([]);
  const [item3Name, setItem3Name] = useState([]);
  const [item3Price, setItem3Price] = useState([]);
  const [item3Qty, setItem3Qty] = useState([]);
  const [item4Name, setItem4Name] = useState([]);
  const [item4Price, setItem4Price] = useState([]);
  const [item4Qty, setItem4Qty] = useState([]);
  const [item5Name, setItem5Name] = useState([]);
  const [item5Price, setItem5Price] = useState([]);
  const [item5Qty, setItem5Qty] = useState([]);
  const [item6Name, setItem6Name] = useState([]);
  const [item6Price, setItem6Price] = useState([]);
  const [item6Qty, setItem6Qty] = useState([]);
  //const [dishNumber, setDishNumber] = useState();
  
useEffect (() =>{
  retrieveHairStyleMenue(); 
}, [foodMenue]);

// useEffect (() =>{
//   setContactEmail(LocalStorageService("get", "userEmail"))
// }, );

 function getToken (){
  return LocalStorageService("get", "token"); 
}; 

function  getEmail (){
  return LocalStorageService("get", "sellerEmail"); 
}; 

function  getPhoneNumber (){
  return LocalStorageService("get", "sellerPhoneNumber"); 
}; 

const  getApiPath = () =>  {   
  return GetUrl("retrieveHairStyleMenueassembling");
};

const retrieveHairStyleMenue = () =>{
const API_PATH = getApiPath(); 
const body = {
  email: getEmail(),
  phoneNumber: getPhoneNumber()
};
 //if( getToken()) {
axios({
  method: "post",     
  url: `${API_PATH}`,
  data: body,
})
.then(result => {
    if (result.status === 200) {      
      const menue = result?.data;
      setResult(menue);           
        console.log("food menu in dom: ", menue);
      }
    })
    .catch(function (error) {    
      console.log(error);
    });  
       
   // }
  };
  
return (
  <div  className="content-akwaba">  
        {results.map((item, i) => {
         LocalStorageService("set", "item1nameandprice", item.Item1Name? item.Item1Name +" £" +item.Item1Price:"");
         LocalStorageService("set", "item2nameandprice", item.Item2Name? item.Item2Name +" £" +item.Item2Price:"");
         LocalStorageService("set", "item3nameandprice", item.Item3Name? item.Item3Name +" £" +item.Item3Price:"");
         LocalStorageService("set", "item4nameandprice", item.Item4Name? item.Item4Name +" £" +item.Item4Price:"");
         LocalStorageService("set", "item5nameandprice", item.Item5Name? item.Item5Name +" £" +item.Item5Price:"");
         LocalStorageService("set", "item6nameandprice", item.Item6Name? item.Item6Name +" £" +item.Item6Price:"");
         

          return (
            <div>
            <Row>           
             <Col md={{ span: 10, offset: 4 }}>  
            <div className ="invoices-container">
          <ul Style="list-style-type: none">
            <li Style="color:blue"><h4><b>Caterer info</b></h4></li>
            <li key={i}>Your service name: {item.CatererCompanyName}</li>         
            <li key={i}>CatererCompanyNumber: {item.CatererCompanyNumber}</li>
            <li key={i}>email: {item.email}</li>
             <p></p>            
            <li key={i}>title: {item.title}</li>
            <li key={i}>firstName: {item.firstName}</li>
             <li key={i}>lastName: {item.lastName}</li>
             <li key={i}>phoneNumber: {item.phoneNumber}</li> 
             <li key={i}>secondary phoneNumber: {item?.CustomerPhoneNumber}</li> 
              <p></p>
          <li Style="color:magenta"><h4><b>Menue info</b></h4></li>
          <li key={i}>Dish name: {item.DishName}</li>                
                
          <li key={i}>{item.Item1Name? item.Item1Name +"("+ item.Item1Qty+ "portion(s))"+ ":"+"£"+item.Item1Price:""}</li>
          <li key={i}>{item.Item2Name? item.Item2Name +"("+ item.Item2Qty+ "portion(s))"+ ":"+"£"+item.Item2Price:""}</li>
          <li key={i}>{item.Item3Name? item.Item3Name +"("+ item.Item3Qty+ "portion(s))"+ ":"+"£"+item.Item3Price:""}</li>
          <li key={i}>{item.Item4Name? item.Item4Name +"("+ item.Item4Qty+ "portion(s))"+ ":"+"£"+item.Item4Price:""}</li>
          <li key={i}>{item.Item5Name? item.Item5Name +"("+ item.Item5Qty+ "portion(s))"+ ":"+"£"+item.Item5Price:""}</li>
          <li key={i}>{item.Item6Name? item.Item6Name +"("+ item.Item6Qty+ "portion(s))"+ ":"+"£"+item.Item6Price:""}</li>
          
      </ul>
      </div> 
             </Col>
           </Row>
      </div>
          )
        })}   
    </div>
   
)
  }

 
export default ProductMenuePageAsProductOwner;