import config from '../config.json';

export default function GetBaseUrl(){   
    if(config.ENVIRONMENT.DEV)
     return "http://localhost:3000/";
     if(config.ENVIRONMENT.LIVE)
    return "https://manblaysaviours.com/";
    if(config.ENVIRONMENT.TEST)
     return "https://groupakwabatech.com/"    
}
