import React, { Component } from "react";
import {NavLink, HashRouter } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import LocalStorageService from "../services/localStorageService";

class Help extends Component {
  render() {
    const cardStyles = {
      container: {
      },
    myComponentStyle :{
      color: 'blue',      
   }   
  }
    return (
      <div className ="container" >
    <p>Welcome to Manblay</p>
<p>About Us</p>
<span>At Manblay catering, we believe in turning moments into memories through the art of exquisite catering. With a passion for culinary excellence and a commitment to impeccable service, we have been delighting clients with our catering expertise.

<p><b>1. Our Services </b><br/></p>
1. Catering 
<p><b>1. Catering for Every Occasion</b><br/></p>
No matter the event, we have the perfect culinary solutions for you. From weddings and corporate events to private parties and special celebrations, our diverse menu options cater to all tastes and preferences.


<p><b>2. Assembling</b><br/></p>
We understand that every does not have the skill and tools tp assemble items purchased online.
That's why we offer flexible and comprehensive service to suit your specific needs. Our experienced technicians will work for you to assemble your item according to manufacturer specifications and guidance.
<br/><p></p>
<p><b>3. merchandise</b><br/></p>

Quality is at the heart of what we do. We source fresh ingredients and overseas components to ensure that every day is a happy day. Our commitment to quality is unparallel.
<br/><p></p>
<p><b>4. Hair styling</b><br/></p>

Our team of professional and courteous entrepreneurs is dedicated to making your event seamless and stress-free. From setup to cleanup, we handle every detail so you can focus on enjoying the occasion.
<br/><p></p>
<p><b>How It Works</b><br/>
<HashRouter> 
<br/><span style = {cardStyles.myComponentStyle}><NavLink to="/helpcatering">Help with catering</NavLink></span><br/>

<br/> <span style = {cardStyles.myComponentStyle}><NavLink to="/helphairstyle">Help with hair styling</NavLink></span><br/>

 <br/><span style = {cardStyles.myComponentStyle}><NavLink to="/helpcommodities">Help with merchandise</NavLink></span><br/>

 <br/><span style = {cardStyles.myComponentStyle}><NavLink to="/helpassembling">Help with assembling</NavLink></span><br/>
</HashRouter> 
</p>

<p><b>Contact Information</b><br/>
info.manblay@gmail.com
</p>
</span>

      </div>
    );
  }
}
 
export default Help;