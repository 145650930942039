import React, { Component } from "react";
 
class MyPage extends Component {
  render() {
    return (
      <div id ="react-template" >
       <p>Menue</p>
      </div>
    );
  }
}
 
export default MyPage;