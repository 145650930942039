import React, {Component,  useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink, Link, HashRouter } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import LocalStorageService from './services/localStorageService';
import DashboardCarousel from './DashboardCarousel';
class HomeDashboard extends Component {
  render() {
    const { t } = this.props;
    LocalStorageService("remove","servicecategory")
    return (
      <div>

        <div>  
      <DashboardCarousel/>
       </div>
       <HashRouter>
        <div>
     <Row> 
        {/* <Col md={{ span: 9, offset: 2}}> */}
        
           <Col id='col-dashboard-1'>           
           <ul id="menu">
            <li>
              <NavLink to="/hairandbeauty"><span className ="header-akwaba-tiles-navlinks1">▶️Hair▫And▫Beauty</span></NavLink>            
              </li>             
            </ul>           
            </Col> 
           
            <Col id='col-dashboard-2'>
            <ul id="menu">
            <li>
              <NavLink to="/merchandise"><span className ="header-akwaba-tiles-navlinks2">▶️Market▫Place</span></NavLink>            
              </li>             
            </ul>
            </Col>
           
            <Col id='col-dashboard-3'> 
            <ul id="menu">
            <li>
              <NavLink to="/DIYTechnicians"><span className ="header-akwaba-tiles-navlinks3">▶️DIY▫Experts</span></NavLink>            
            </li>             
            </ul>
          </Col>
         
          <Col id='col-dashboard-4'>
          <ul id="menu">
          <li>
              <NavLink to="/catering"><span className ="header-akwaba-tiles-navlinks4">▶️Catering</span></NavLink>            
           </li>         
           </ul>
          </Col> 
          
       </Row>
       </div>   
     </HashRouter> 
     </div> 
      
    );
  }
}

export default withTranslation() (HomeDashboard);
