
import { useTranslation } from 'react-i18next';
import { React,useState, useEffect, useRef} from "react";
import axios from "axios";
import Select from 'react-select';
import GetUrl from '../../services/urlService';
import LocalStorageService from '../../services/localStorageService';
import {Row, Col} from 'react-bootstrap';

const MakePayment = () => {

  const [title, setTitle] = useState('');
  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [mobilenumber, setMobileNumber] = useState('');
  const [sortcode, setSortCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [results, setResult] = useState([]);
 
  function loginToken (){
    return LocalStorageService("get", "token")
  }; 

  // const hasLoadedBefore = useRef(true)
  // useEffect(()=> {
  //   if(hasLoadedBefore.current){
  //     //your initializing code runs only once
  //     console.log("Effect ran")
  //     hasLoadedBefore.current = false;
  //   } else{
  //     //subsequent renders
  //   }
  // }, [])

  useEffect (() =>{
    setContactEmail(LocalStorageService("get", "userEmail"));
    fetchAccountDetails()
  }, [])

   function getApiPath () { 
    return GetUrl("getPaymentCard")
  };

   
//  const email = loginEmail();
 const token = loginToken();

    const  fetchAccountDetails = () => {
// if(!hasLoadedBefore){

        const API_PATH = getApiPath();
          axios({
            method: "get",
            url: `${API_PATH}`            
          })
            .then((result) => {
              if (result.status === 200) {         
                setResult(result.data);       
              }
            })
            .catch(function (error) {    
              console.log(error);
            }); 
        }
    //  }

// return (
//   <div  className="content-akwaba">
     
//           <div>
//             <Row>    
return (
  <div  className="content-akwaba">
        {results.map((item) => { 
          return (
            <div>
            <Row >           
            <Col md={{ span: 6, offset: 2 }}>  
            <div className ="invoices-container">
          <ul Style="list-style-type: none">         
            <li Style="color:Black"><h3><b>Account details: </b></h3></li>
            <li key={item.id}>Account Number: {results[0].Account}</li>
            <li key={item.id}>Sort: {results[0]?.Sort}</li> 
            <li key={item.id}>Bank: {results[0]?.Bank}</li>
            <li key={item.id}>Holder: {results[0]?.FullName}</li> 
            <p></p>
           </ul>
           </div>
            </Col>         
           </Row>
         </div>
          )
        })}
        
        </div>

)
      }
export default (MakePayment);