
//https://www.itsolutionstuff.com/post/password-and-confirm-password-validation-in-reactexample.html

import { useTranslation } from 'react-i18next';
import { React,useEffect,useState} from "react";
import axios from "axios";
import GetApis from '../pages/GetApis';
import Select from 'react-select';
import CONFIG from '../../config.json';
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import DateTimePicker from 'react-datetime-picker';
import UploadPictures from './UploadPictures';
import UploadVideo from './UploadVideo';
import GetUrl from "../services/urlServiceMerchandise";
import LocalStorageService from '../../services/localStorageService';
import ReactPlayer from 'react-player';
import getAppName from '../services/getAppName';
 
//import VerifyToken from '../services/localStorageService';
 
const UploadTextInputs = () => {
  getAppName();
  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [contactEmail, setContactEmail] = useState(localStorage.getItem('userEmail'));
  const [contactPhone, setContactPhone] = useState('');
  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');
  const [size, setSize] = useState('');
  const [state, setState] = useState('');
  const [colour, setColour] = useState('');
  const [openingModel, setOpeningModel] = useState('');  
  const [image, setImage] = useState('');
  const [serviceModel, setServiceModel] = useState('');
  const [eventCatering, setEventCatering] = useState('');
  const [firstoptionalimage, setOptionalImage1] = useState('');
  const [secondoptionalimage, setOptionalImage2] = useState('');
  const [datepickeravailfrom, setAvailfrom] = useState();
  const [datepickeravailto, setavailto] = useState();
  const [note, setNote] = useState('');
  const [dateOfEvents, setDateOfEvents] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [seesubmitbutton, setSeesubmitbutton] = useState(true);  
  const [clickedButtonButNotPosted, setClickedButtonButNotPosted] = useState('');
  const [clickedNotPostedMessage, setClickedNotPostedMessage] = useState('');
  const [fnameerrormsg, setFirstNameerrormsg] = useState('');
  const [lnameerrormsg, setLastNameerrormsg] = useState('');
  const [titleerrormsg, setTitleerrormsg] = useState(''); 
  const [emailerrormsg, setEmailerrormsg] = useState('');
  const [phonenumbererrormsg, setPhoneNumbererrormsg] = useState('');
  const [reasonerrormsg, setReasonerrormsg] = useState('');
  const [seenote, setSeenote] = useState(true);
  const [isValid, setIsvalid] = useState(false);
  const [env, setEnvironment] = useState('');
  const [demoVideoPath, setDemoVideoPath] = useState('');
  const [contractType, setContractType] = useState('');
  const [availability, setAvailability] = useState('');
  const [geographicalCoverage, setGeographicalCoverage] = useState('');
  const [shopName, setShopName] = useState('');
  

  const { t } = useTranslation();

  function getApiPath () { 
    return GetUrl("insertAssetcommodities")
  };

  function loginToken (){  
    return LocalStorageService("get", "token")
  }; 

  const videosrcdev = `registerdish.mp4`;
  const videodev = require(`../../serverMerchandise/assets/videos/${videosrcdev}`).default;
  const videolive = `/videos/${videosrcdev}`??'';

  useEffect(() => {
    LoadDemoVideo();
  }, [])


  useEffect(() => {
    setEnvironment(LocalStorageService("get", "env"));
  }, [])
  

function LoadDemoVideo(){
  if(env=='dev')
  {
    setDemoVideoPath(videodev)
  }
  else{
    setDemoVideoPath(videolive)
  }
}
//  const email = loginEmail();
 const token = loginToken();

  const isLoggedin = token!=null;

  function execute (event)  {
    LocalStorageService("remove", "insertedId"); 
    setEnvironment(LocalStorageService("get", "env"));
    event.preventDefault();
    if (validate()) {
      console.info("Valid Form");   
      handleSubmit(event);    
    } else {
        setClickedButtonButNotPosted(true);
        setClickedNotPostedMessage(<p>{t("pages.contact.text.msginvalidform")} </p>);  
        console.log("Data was not sent");     
      console.error("Invalid Form");
      return;
    }
  };

  const handleSubmit = event => {
   
   event.preventDefault(); // 👈️ prevent page refresh
   const body ={fname, lname, title, openingModel,
    note,category,country,city, contactEmail,contactPhone,
    itemName,description,size,datepickeravailfrom,contractType,  
    datepickeravailto , availability,  geographicalCoverage, shopName}; 
   
   setClickedButtonButNotPosted(<p>{t("pages.contact.text.msginvalidform")} </p>);
              
      const API_PATH = getApiPath();     
      axios({
        method: "post",     
        url: `${API_PATH}`,
        data: body,
      })
        .then((result) => {
          if (result.status === 200) { 
           console.log({result})
            setMessageSent(true); 
            LocalStorageService("set", "email", contactEmail);
            LocalStorageService("set", "insertedId", result.data);        
            setServiceModel('');
            setAvailability('');
            setContractType('');
            setEventCatering('');
            setFirstName('');
            setLastName('');
            setTitle('');
            setCategory('');
            setCountry('');
            setCity('');
            setContactEmail('');
            setContactPhone('');
            setItemName('');
            setDescription('');
            setGeographicalCoverage('');
            setSize('');
            setColour('');
            setShopName('');
            setOpeningModel('');
            setImage('');
            setOptionalImage1('');
            setOptionalImage2('');
            setAvailfrom('');
            setavailto('');
            setState('');
            setNote('');
            setSeenote(false);
            setDateOfEvents('');          
            setFirstNameerrormsg('');
            setLastNameerrormsg('');
            setTitleerrormsg('');
            setEmailerrormsg('');
            setPhoneNumbererrormsg('');
            setReasonerrormsg('');           
            setSeesubmitbutton(false);
            setClickedButtonButNotPosted(false);
            setClickedNotPostedMessage('');
            setContractType();            
          }
        })
        .catch(function (error) {    
          console.log(error);
        });     
    
  }

 const  validate = ()=> {
    let isValid = true; 
    if (!title) {
      isValid = false;
      setTitleerrormsg(<p>{t("pages.contact.text.titleinvalidmsg")}</p>);
    }
    
    if (!fname) {
      isValid = false;
      setFirstNameerrormsg(<p>{t("pages.contact.text.firstnameinvalidmsg")}</p>);
    }

    if (!lname) {
      isValid = false;
      setLastNameerrormsg(<p>{t("pages.contact.text.lastnameinvalidmsg")}</p>);
    }

    if (!contactPhone) {
      isValid = false;
      setPhoneNumbererrormsg(<p>{t("pages.contact.text.phoneinvalidmsg")}</p>);
     }
    if (!contactEmail) {
      isValid = false;
      setEmailerrormsg(<p>{t("pages.contact.text.emailinvalidmsg")}</p>);
    }

    if (typeof contactEmail !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(contactEmail)) {
        isValid = false;
        setEmailerrormsg(<p>{t("pages.contact.text.emailpatterninvalidmsg")}</p>);
      }
    }

    
    if (typeof note !== "undefined") {
      var obscene = ["porn","fuck","nude","naked","pussy","dick","cum","ass","pornography", 
    "nue", "vagina"]
      var arrReason = note.toLowerCase().split(" ");
    // Iterate through each element in the
    // first array and if some of them 
    // include the elements in the second
    // array then return true
      var containsObscene = arrReason.some(item => obscene.includes(item))
    if ((!note) || containsObscene){
      isValid = false;
      setReasonerrormsg(<p>{t("pages.contact.text.subjectinvalidmsg")}</p>);
    }    
    setIsvalid(isValid);
    return isValid;
  }
  };
  
  return (
   <div  className="content-akwaba">
    {isLoggedin && (  <form action="#">
        <div className="case-form" Style=""><p>Fill this form to populate your property details</p></div>   
       <Accordion defaultActiveKey="0">  
        <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
    <div className="case-form" Style=""><p>Watch a demo</p></div>  
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body className="content-accordion" >
        <div className="col-md-6 offset-3">
        <div className="body-pannel">   
        <video width="300px" height="400px" controls>
           <source src={videolive} type="video/mp4" />
      </video>
      </div>
        </div>
        </Card.Body>
    </Accordion.Collapse>
  </Card>
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
    Your personal info   
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body className="content-accordion" >
        <div className="col-md-9 offset-4">
      <div className="form-group">
        <label>{t("pages.contact.text.title")} </label>        
        <select className="form-group  option-group" name= "title" id="title" value={title}  onChange={event => setTitle(event.target.value)} >
                <option value="">{t("pages.contact.default")}</option>
                <option value={t("pages.contact.sir")}>{t("pages.contact.sir")}</option>
                <option value={t("pages.contact.madam")}>{t("pages.contact.madam")}</option>
                <option value={t("pages.contact.unspecified")}>{t("pages.contact.unspecified")}</option>  
                <option value={t("pages.contact.other")}>{t("pages.contact.other")}</option>
                <option value="Royal">Royal</option>                                 
              </select>
          <div className="text-danger">{titleerrormsg}</div>
        </div>

      <div className="form-group">
        <label>Your first name</label>
        <input
         readonly
          id="firstname"
          name="firstname"
          type="text"
          value={fname}
          onChange={event => setFirstName(event.target.value)}          
          placeholder="your first name"
          className= "blured-input"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
         <label>Your last name</label>
        <input
          readonly 
          id="lastname"
          name="lastname"
          type="text"
          value={lname}
          onChange={event => setLastName(event.target.value)}
          placeholder="Estate and Building"
          className= "blured-input"
        />
          <div className="text-danger">{lnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>Your email</label>
        <input
        readonly
          id="contactEmail"
          name="contactEmail"
          type="text"
          value={contactEmail}
          onChange={event => setContactEmail(event.target.value)}
          placeholder="something@domain.com"
          className= "blured-input"
        />
          <div className="text-danger">{emailerrormsg}</div>
      </div>

      <div className="form-group">
              <label>Your phone</label>
         <input
         readonly
          id="contactPhone"
          name="contactPhone"
          type="text"
          value={contactPhone}
          onChange={event => setContactPhone(event.target.value)} 
          placeholder="123456789"
          className= "blured-input"
        />
          <div className="text-danger">{phonenumbererrormsg}</div>
        </div>
        </div>
        </Card.Body>
    </Accordion.Collapse>
  </Card>


  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
        Your commodity menu     
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body className="content-accordion">
        
        <div className ="col-md-9 offset-4">
      <div className="form-group">
        <label>{t("Category")} </label>
          <select className ="form-group  option-group" name= "category" id="category" value={category}  onChange={event => setCategory(event.target.value)} >
            <option value="">Select category</option>
            <option value="Food">Agro-Food</option>
            <option value="Meat">Meat</option>         
            <option value="Aviary">Aviaray</option> 
            <option value="Produce">Produce</option>
            <option value="Clothes">Clothes</option>
            <option value="Medical">Medical</option>
            <option value="RealEstate">Real-Estate</option>
            <option value="Wheeledwithendgines">Wheeled with Engines</option>                            
            <option value="Othercategories">Other categories</option>  
          </select>
          <div className="text-danger">{fnameerrormsg}</div>
        </div>


        <div className="form-group">
        <label>Where do you live</label>
        <input
          id="itemName"
          name="itemName"
          type="text"
          value={itemName}
          onChange={event => setItemName(event.target.value)}          
          placeholder="Your town"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>


        <div className="form-group">
        <label>Dates you are available to provide service example(all seasons all year round)</label>
        <input
          id="availability"
          name="availability"
          type="text"
          value={availability}
          onChange={event => setAvailability(event.target.value)}          
          placeholder="Every day. Otherwise specify the days"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>


        <div className="form-group">
        <label>Short description of how you trade your commodities. This will be displayed on your profile.</label>
        <input
          id="description"
          name="description"
          type="text"
          value={description}
          onChange={event =>setDescription(event.target.value)}          
          placeholder="Description"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>


        <div className="form-group">
        <label>Contract type </label>      
          <select className="form-group  option-group" name= "contractType" id="contractType" value={contractType}  onChange={event => setContractType(event.target.value)} >
            <option value="">Select a contract</option>
            <option value="HomeService">I trade from home</option>            
            <option value="InMyShop">I possess a shop</option>  
            <option value="Other">Other</option>                             
          </select>
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>Geographical location you can provide service for</label>
          <input
          id="geographicalCoverage"
          name="geographicalCoverage"
          type="text"
          value={geographicalCoverage}
          onChange={event => setGeographicalCoverage(event.target.value)}          
          placeholder="Example(London)"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>Opening hours</label>
      
             <select className="form-group  option-group" name= "openingModel" id="openingModel" value={openingModel}  onChange={event => setOpeningModel(event.target.value)} >
                <option value="">If shop, Open hours</option>
                <option value="7j24">24/7 </option>
                <option value="Fewdays">A few days a week</option>
                <option value="Weekends">Weekends </option>
                <option value="NotApplicable">Not applicable </option>                        
              </select>
          <div className="text-danger">{fnameerrormsg}</div>
      </div>
      </div>        
        </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
     If you have a shop, your shop name, location and service model
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body className="content-accordion">
      <div className ="col-md-9 offset-4">

      <div className="form-group">
        <label>Your shop name</label>
        <input
          id="shopName"
          name="shopName"
          type="text"
          value={shopName}
          onChange={event => setShopName(event.target.value)}          
          placeholder={t("shop Name")}
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>Country where you are normaly based</label>
        <input
          id="country"
          name="country"
          type="text"
          value={country}
          onChange={event => setCountry(event.target.value)}          
          placeholder={t("country")}
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>City </label>
        <input
          id="city"
          name="city"
          type="text"
          value={city}
          onChange={event => setCity(event.target.value)}          
          placeholder={t("city")}
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>


       
        <div className="form-group">
        <label>Delivery size(How many people you can do hair cut/dress if you invited to be stylist at an event) </label>
        <select className="form-group  option-group" name= "size" id="size" value={size}  onChange={event => setSize(event.target.value)} >
                <option value="">Select Event  size</option>
                <option value="canMicroEvent">Less than 50 people event </option>
                <option value="canMiniEvent">50 to 100 people event </option>
                <option value="canSmallEvent">100 to 200 people event </option>
                <option value="canMediumEvent">200 to 350 people event </option>
                <option value="canBigEvent">350 to 500 people event </option>
                <option value="canLargeEvent">500 and more people event </option> 
                <option value="cannotEvent">No event </option>      
              </select>
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

       </div>  
        </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="3">
     Description  
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="3">
      <Card.Body className="content-accordion">
       <div className ="col-md-9 offset-4"> 
        {seenote && (<div className="form-group ">               
        <span><label>Describe in 100 words commodities as clearly as you can. This will be displayed in your menu detail page.</label></span>
        <textarea
          id="note"
          name="note"
          type="text"
          rows={15}
          value={note}          
          onChange={event => setNote(event.target.value)}
          placeholder="Describe your dish"//{t("pages.contact.text.subjectph")}
        />
          <div className="text-danger">{reasonerrormsg}</div>      
       </div>)}

      {messageSent && (<span>Click 'picture Uploads' here below to continue</span>)} 
        
       {seesubmitbutton && (  <input
              className="btn btn-primary"
              type="submit"
              onClick={(e) =>  {execute(e)}}              
              value="Save and continue"
            />
          )}      
        </div>
        </Card.Body>
    </Accordion.Collapse>
  </Card> 
  
  {messageSent && (
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="4"> 
    Picture upload
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="4">
      <Card.Body className="content-accordion">
      <div>      
      
        <div className="justify-content">    
        <div className='col-md-9 offset-4'>
       
        <UploadPictures/>
        </div>
        </div>
    
      </div>
      </Card.Body>
    </Accordion.Collapse>
  </Card>  
    )} 
</Accordion>      
{clickedButtonButNotPosted && (
        <div className="text-danger">
          {clickedNotPostedMessage} <br />                  
        </div>
         )}
      </form>)} 
    </div>
  );
};

export default UploadTextInputs;



